import {llget, put, post, del, postUserId, putUserId, delUserId,get } from "./base";
import qs from "qs";

// 查询患者接口
export function getPatient(startTime,endTime,name,pageNum,pageSize){
	return get(`/v3/caseFolder?startTime=${startTime}&endTime=${endTime}&name=${name}&pageNum=${pageNum}&pageSize=${pageSize}`) 
}

// 查询病程接口
export function getCourse(startTime,endTime,name,pageNum,pageSize){
	return get(`/v3/coursePatient?startTime=${startTime}&endTime=${endTime}&name=${name}&pageNum=${pageNum}&pageSize=${pageSize}`) 
}